import { configureStore } from '@reduxjs/toolkit';
import adminReducer from './reducers/adminReducer';
import dialogReducer from './reducers/dialogReducer';
import projectReducer from './reducers/projectReducer';
import proofingReducer from './reducers/proofingReducer';
import userReducer from './reducers/userReducer';
import sponsorshipPageReducer from './reducers/sponsorshipPageReducer';
import sponsorshipReducer from './reducers/sponsorshipReducer';
import marketPageReducer from './reducers/marketPageReducer';
import marketReducer from './reducers/marketReducer';
import platformPageReducer from './reducers/platformPageReducer';
import platformReducer from './reducers/platformReducer';
import benchmarkingReducer from './reducers/benchmarkingReducer';

const Store = configureStore({
  reducer: {
    user: userReducer,
    admin: adminReducer,
    project_data: projectReducer,
    dialog: dialogReducer,
    proofing: proofingReducer,
    sponsorshipPage: sponsorshipPageReducer,
    sponsorship: sponsorshipReducer,
    marketPage: marketPageReducer,
    market: marketReducer,
    platformPage: platformPageReducer,
    platform: platformReducer,
    benchmarking: benchmarkingReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof Store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof Store.dispatch;

export default Store;
