import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
// State
import { RootState } from '../../../Store/Store';
import { formatTitle } from '../../../helpers/formatTitle';

const AdminTitle: React.FC = () => {
  const params = useParams();
  const { user_details_drawer, edit_user_drawer, create_user_drawer } = useSelector(
    ({ admin }: RootState) => admin,
  );
  const user_details = useSelector(({ user }: RootState) => user.user_details);
  const { market_details_drawer, edit_market_drawer, create_market_drawer } = useSelector(
    ({ marketPage }: RootState) => marketPage,
  );
  const market_details = useSelector(({ market }: RootState) => market.market_details);
  const { platform_sponsorship_types, create_sponsorship_type_drawer, platform_activity_types } =
    useSelector(({ platformPage }: RootState) => platformPage);

  return (
    <Grid item mb={5}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography variant="h1">
            {params['*'] === 'general' && params['*']}
            {params['*'] === 'users' &&
              (user_details && (user_details_drawer || edit_user_drawer)
                ? user_details?.name
                : create_user_drawer
                ? 'Create New User'
                : params['*'])}
            {params['*'] === 'markets' &&
              (market_details && (market_details_drawer || edit_market_drawer)
                ? market_details?.name
                : create_market_drawer
                ? 'Create New Market'
                : params['*'])}
            {params['*'] === 'sponsorship-platforms' &&
              ((platform_sponsorship_types || create_sponsorship_type_drawer) &&
              !platform_activity_types
                ? 'Sponsorship Types'
                : platform_activity_types
                ? 'Activity Type'
                : params['*'] && formatTitle(params['*']))}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminTitle;
