import { createSlice, Slice } from '@reduxjs/toolkit';
import { AdminReducerTypes } from './types/adminReducer.types';

const initialState: AdminReducerTypes = {
  edit_user_drawer: false,
  create_user_drawer: false,
  user_details_drawer: false,
  delete_user_dialog: false,
  disable_user_dialog: false,
  role_details_drawer: false,
  create_role_drawer: false,
  expand_permission_tree_dialog: false,
  delete_platform_dialog: false,
  delete_platform_id: null,
  delete_platform_name: '',
};

export const adminSlice: Slice<AdminReducerTypes> = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    set_edit_user_drawer: (state, action) => {
      state.edit_user_drawer = action.payload;
    },
    set_create_user_drawer: (state, action) => {
      state.create_user_drawer = action.payload;
    },
    set_user_details_drawer: (state, action) => {
      state.user_details_drawer = action.payload;
    },
    set_delete_user_dialog: (state, action) => {
      state.delete_user_dialog = action.payload;
    },
    set_disable_user_dialog: (state, action) => {
      state.disable_user_dialog = action.payload;
    },
    set_role_details_drawer: (state, action) => {
      state.role_details_drawer = action.payload;
    },
    set_create_role_drawer: (state, action) => {
      state.create_role_drawer = action.payload;
    },
    set_expand_permission_tree_dialog: (state, action) => {
      state.expand_permission_tree_dialog = action.payload;
    },
    set_delete_platform_dialog: (state, action) => {
      state.delete_platform_dialog = action.payload.open;
      state.delete_platform_id = action.payload.id;
      state.delete_platform_name = action.payload.name;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  set_edit_user_drawer,
  set_create_user_drawer,
  set_user_details_drawer,
  set_delete_user_dialog,
  set_disable_user_dialog,
  set_role_details_drawer,
  set_create_role_drawer,
  set_expand_permission_tree_dialog,
  set_delete_platform_dialog,
} = adminSlice.actions;

export default adminSlice.reducer;
