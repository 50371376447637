import { createTheme } from '@mui/material/styles';
const HeinekenCoreBold = require('./css/Fonts/Heineken-Core/HEINEKENCore-Bold.otf');
const HeinekenCoreBoldItalic = require('./css/Fonts/Heineken-Core//HEINEKENCore-BoldItalic.otf');
const HeinekenCoreLight = require('./css/Fonts/Heineken-Core/HEINEKENCore-Light.otf');
const HeinekenCoreLightItalic = require('./css/Fonts/Heineken-Core/HEINEKENCore-LightItalic.otf');
const HeinekenCoreMedium = require('./css/Fonts/Heineken-Core/HEINEKENCore.otf');
const HeinekenCoreMediumItalic = require('./css/Fonts/Heineken-Core/HEINEKENCore-Italic.otf');
const HeinekenCoreExtraBold = require('./css/Fonts/Heineken-Core/HEINEKENCore-ExtraBold.otf');

// Update the Button's variant options to include a login option
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    login: true;
  }
}

export const Theme = createTheme({
  palette: {
    primary: {
      main: '#13670B',
      light: '#0E4D08',
      dark: '#093305',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#FFF',
      light: 'rgba(19, 103, 11, 0.04)',
      dark: 'rgba(0, 0, 0, 0.04)',
      contrastText: '#13670B',
    },
    error: {
      main: '#E20613',
    },
  },
  typography: {
    allVariants: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    fontFamily: [
      'Heineken Core',
      'Heineken Core Light',
      'Heineken Core Bold',
      'Heineken Core Italic',
      'Heineken Core Light Italic',
      'Heineken Core Bold Italic',
      'Heineken Core Extra Bold',
    ].join(','),
    h1: {
      fontFamily: 'Heineken Core Bold',
      fontSize: 34,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '41.99px',
      letterSpacing: '0.25px',
      textTransform: 'capitalize',
    },
    h2: {
      fontFamily: 'Heineken Core',
      fontSize: 20,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '32px',
      letterSpacing: 0.15,
      textTransform: 'capitalize',
    },
    h3: {
      fontFamily: 'Heineken Core',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '25.6px',
      letterSpacing: 0.15,
      textTransform: 'capitalize',
    },
    // Login page
    h4: {
      fontFamily: 'Heineken Core',
      fontSize: 34,
      fontWeight: 700,
      lineHeight: 'normal',
    },
    // Nested routes
    h5: {
      fontFamily: 'Heineken Core',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: 0.4,
      textTransform: 'uppercase',
    },
    h6: {
      fontFamily: 'Heineken Core',
      fontSize: 14,
      lineHeight: '24px',
      color: '#000000',
    },
    subtitle1: {
      fontFamily: 'Heineken Core Bold',
      fontSize: '16px',
      lineHeight: '18px',
      color: '#083D49',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontFamily: 'Heineken Core',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: 0.4,
      textTransform: 'uppercase',
    },
    body1: {
      fontFamily: 'Heineken Core',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: 0.15,
    },
    body2: {
      fontFamily: 'Heineken Core',
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '12px',
      letterSpacing: 0.15,
    },
    button: {},
    caption: {
      fontFamily: 'Heineken Core Bold',
    },
    overline: {},
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          marginRight: 2,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        body {
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          background-color: #ffffff !important;
        }
  
        @font-face{
          font-family:"Heineken Core Light";
          src:url(${HeinekenCoreLight}) format("opentype");
        }
        
        @font-face{
          font-family:"Heineken Core Light Italic";
          src:url(${HeinekenCoreLightItalic}) format("opentype");
        }
  
       @font-face{
          font-family:"Heineken Core";
          src:url(${HeinekenCoreMedium}) format("opentype");
        }
        
        @font-face{
          font-family:"Heineken Core Italic";
          src:url(${HeinekenCoreMediumItalic}) format("opentype");
        }
        
        @font-face{
          font-family:"Heineken Core Bold";
          src:url(${HeinekenCoreBold}) format("opentype");
        }
        
        @font-face{
          font-family:"Heineken Core Bold Italic";
          src:url(${HeinekenCoreBoldItalic}) format("opentype");
        }
        
        @font-face{
          font-family:"Heineken Core Extra Bold";
          src:url(${HeinekenCoreExtraBold}) format("opentype");
        }
      `,
    },
    MuiDrawer: {
      defaultProps: {
        PaperProps: {
          style: {
            padding: 20,
            minWidth: 400,
            width: 400,
            height: '100%',
            backgroundColor: '#f8f9fa',
          },
        },
      },
    },
    MuiTableCell: {
      defaultProps: {
        style: {
          fontFamily: 'Heineken Core',
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          letterSpacing: 0.17,
        },
      },
    },
    MuiFormLabel: {
      defaultProps: {
        style: {
          color: 'rgba(0, 0, 0, 0.6)',
        },
      },
    },
    /*
    MuiSelect: {
      defaultProps: {
        style: {
          backgroundColor: 'white',
          padding: '3px',
        },
      },
    },*/
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            borderRadius: 4,
            fontSize: 14,
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: 0.46,
            textTransform: 'uppercase',
          },
        },
        {
          props: { variant: 'text' },
          style: {
            textTransform: 'capitalize',
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 'normal',
            fontFamily: 'Heineken Core',
            borderRadius: '2px',
            color: 'rgba(255, 255, 255, 0.8)',
            height: 36,
            '&:hover': {
              color: '#FFF',
              borderRadius: '4px',
            },
          },
        },
        {
          props: { variant: 'login' },
          style: {
            background: '#FFF',
            borderRadius: 4,
            boxShadow: 'none',
            fontSize: 15,
            fontWeight: 700,
            lineHeight: 'normal',
            letterSpacing: 0.46,
            textTransform: 'uppercase',
            width: '100%',
            height: 42,
            color: '#13670B',
            padding: '8px 22px',
            '&:hover': {
              background: '#DEE2E6',
              color: '#13670B',
            },
            '&.Mui-disabled': {
              color: 'rgba(255, 255, 255, 0.46)',
              backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
          },
        },
      ],
    },
    MuiTab: {
      defaultProps: {
        style: {
          color: 'black',
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'secondary',
      },
    },
  },
});
