import React from 'react';
import { Alert, Button, Grid, IconButton, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessToastState } from '../../Store/action';
import { Close } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../Store/Store';

const SuccessToast: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const success_toast_state = useSelector(({ dialog }: RootState) => dialog.success_toast_state);
  const success_toast_message = useSelector(
    ({ dialog }: RootState) => dialog.success_toast_message,
  );
  const success_toast_internal = useSelector(
    ({ dialog }: RootState) => dialog.success_toast_internal,
  );
  const success_toast_artwork_id = useSelector(
    ({ dialog }: RootState) => dialog.success_toast_artwork_id,
  );

  const handleCloseToast = () => {
    dispatch(setSuccessToastState(false, ''));
  };

  const handleNavigation = () => {
    navigate(
      `/live-projects/${success_toast_artwork_id}/${
        success_toast_internal ? 'internal-review' : 'client-review'
      }`,
    );
  };

  if (
    location.pathname ===
    `/live-projects/${success_toast_artwork_id}/${
      success_toast_internal ? 'internal-review' : 'client-review'
    }`
  ) {
    if (success_toast_internal) {
      console.log('Success');
    } else {
      console.log('Error');
    }
  }

  const action = (
    <Grid container direction="row" alignItems="center">
      {success_toast_artwork_id &&
        location.pathname !==
          `/live-projects/${success_toast_artwork_id}/${
            success_toast_internal ? 'internal-review' : 'client-review'
          }` && (
          <Button color="secondary" size="small" onClick={handleNavigation}>
            TAKE ME THERE
          </Button>
        )}
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <Close fontSize="small" />
      </IconButton>
    </Grid>
  );

  return (
    <Snackbar
      open={success_toast_state}
      autoHideDuration={10000}
      onClose={handleCloseToast}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Alert
        onClose={handleCloseToast}
        severity="success"
        sx={{ width: '100%', alignItems: 'center' }}
        action={action}>
        {success_toast_message}
      </Alert>
    </Snackbar>
  );
};

export default SuccessToast;
