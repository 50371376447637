import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
// State
import { RootState } from '../../../Store/Store';
// Component
import NavTabComponent from '../../../Components/Navigation/NavTabComponent';

const AdminNavigation: React.FC = () => {
  const user = useSelector(({ user }: RootState) => user.user);

  return (
    <Grid
      container
      direction="row"
      flexWrap="nowrap"
      justifyContent="space-between"
      px={2}
      bgcolor="#FFF"
      borderBottom="1px solid #D3D3D3">
      <NavTabComponent tabName="general" />
      <NavTabComponent tabName="users" />
      <NavTabComponent tabName="markets" />
      <NavTabComponent tabName="sponsorship-platforms" />
    </Grid>
  );
};

export default AdminNavigation;
