import { createSlice, Slice } from '@reduxjs/toolkit';
import { PlatformPageReducerTypes } from './types/platformPageReducer.types';

const initialState: PlatformPageReducerTypes = {
  platform_sponsorship_types: false,
  create_sponsorship_type_drawer: false,
  platform_activity_types: false,
  create_activity_type_drawer: false,
};

export const platformPageSlice: Slice<PlatformPageReducerTypes> = createSlice({
  name: 'platformPage',
  initialState,
  reducers: {
    set_platform_sponsorship_types: (state, action) => {
      state.platform_sponsorship_types = action.payload;
    },
    set_create_sponsorship_type_drawer: (state, action) => {
      state.create_sponsorship_type_drawer = action.payload;
    },
    set_platform_activity_types: (state, action) => {
      state.platform_activity_types = action.payload;
    },
    set_create_activity_type_drawer: (state, action) => {
      state.create_activity_type_drawer = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  set_platform_sponsorship_types,
  set_create_sponsorship_type_drawer,
  set_platform_activity_types,
  set_create_activity_type_drawer,
} = platformPageSlice.actions;

export default platformPageSlice.reducer;
