import { createSlice, Slice } from '@reduxjs/toolkit';
import { SponsorshipReducerTypes } from './types/sponsorshipReducer.types';

const initialState: SponsorshipReducerTypes = {
  sponsorship: null,
  loaded: true,
  sponsorships: {
    loading: false,
    data: [],
    error: '',
    meta: null,
  },
  sponsorship_details: null,
};

export const sponsorshipSlice: Slice<SponsorshipReducerTypes> = createSlice({
  name: 'sponsorship',
  initialState,
  reducers: {
    set_sponsorship: (state, action) => {
      state.sponsorship = action.payload;
    },
    set_sponsorships: (state, action) => {
      state.sponsorships = action.payload;
    },
    set_sponsorship_details: (state, action) => {
      state.sponsorship_details = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_sponsorship, set_sponsorships, set_sponsorship_details } =
  sponsorshipSlice.actions;

export default sponsorshipSlice.reducer;
