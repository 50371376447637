import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Theme } from './Theme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import store from '../Store/Store';
import AllRoutes from './AllRoutes';
import Header from '../Components/Header/Header';
import CssBaseline from '@mui/material/CssBaseline';
import ErrorToast from '../Components/ErrorToast/ErrorToast';
import SuccessToast from '../Components/SuccessToast/SuccessToast';
import { QueryClient, QueryClientProvider } from 'react-query';

const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={Theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <BrowserRouter>
              <Header>
                <AllRoutes />
              </Header>
              <SuccessToast />
            </BrowserRouter>
            <ErrorToast />
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
