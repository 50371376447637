import React from 'react';
import { IconButton, Snackbar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorToastState } from '../../Store/action';
import { Close } from '@mui/icons-material';
import { AppDispatch, RootState } from '../../Store/Store';

const ErrorToast: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch<AppDispatch>();
  const error_toast_state = useSelector(({ dialog }: RootState) => dialog.error_toast_state);
  const error_toast_message = useSelector(({ dialog }: RootState) => dialog.error_toast_message);

  const handleCloseToast = () => {
    dispatch(setErrorToastState(false, ''));
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
      <Close fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={error_toast_state}
      autoHideDuration={6000}
      onClose={handleCloseToast}
      message={error_toast_message}
      action={action}
    />
  );
};

export default ErrorToast;
