import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Grid } from '@mui/material';
// Components
import SuspenseContainer from '../../../Components/Suspense/SuspenseContainer';
// Pages
const General = lazy(() => import('../pages/General/General'));
const Users = lazy(() => import('../pages/Users/Users'));
const Markets = lazy(() => import('../pages/Markets/Markets'));
const Platforms = lazy(() => import('../pages/Platforms/Platforms'));

const AdminRoutes: React.FC = () => (
  <Grid item paddingY={3}>
    <Routes>
      <Route
        path="general"
        element={
          <SuspenseContainer>
            <General />
          </SuspenseContainer>
        }
      />
      <Route
        path="users"
        element={
          <SuspenseContainer>
            <Users />
          </SuspenseContainer>
        }
      />
      <Route
        path="markets"
        element={
          <SuspenseContainer>
            <Markets />
          </SuspenseContainer>
        }
      />
      <Route
        path="sponsorship-platforms"
        element={
          <SuspenseContainer>
            <Platforms />
          </SuspenseContainer>
        }
      />
      <Route
        path="*"
        element={
          <SuspenseContainer>
            <Navigate to="general" />
          </SuspenseContainer>
        }
      />
    </Routes>
  </Grid>
);

export default AdminRoutes;
