import { createSlice, Slice } from '@reduxjs/toolkit';
import { MarketReducerTypes } from './types/marketReducer.types';

const initialState: MarketReducerTypes = {
  market: null,
  loaded: true,
  markets: {
    loading: false,
    data: [],
    error: '',
    meta: null,
  },
  market_details: null,
};

export const marketSlice: Slice<MarketReducerTypes> = createSlice({
  name: 'market',
  initialState,
  reducers: {
    set_market: (state, action) => {
      state.market = action.payload;
    },
    set_markets: (state, action) => {
      state.markets = action.payload;
    },
    set_market_details: (state, action) => {
      state.market_details = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_market, set_markets, set_market_details } = marketSlice.actions;

export default marketSlice.reducer;
