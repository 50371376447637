import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { formatTitle } from '../../helpers/formatTitle';

const classes = {
  subLink: {
    textDecoration: 'none',
    width: '100%',
    cursor: 'pointer',
  },
};

const NavTabComponent: React.FC<{ tabName: string }> = ({ tabName }) => {
  const params = useParams();
  const title = formatTitle(tabName);

  return (
    <Grid item xs={12} sm={3}>
      <Link to={tabName} style={classes.subLink}>
        <Typography
          variant="h5"
          p={1}
          textAlign="center"
          sx={{
            color: params['*'] === tabName ? '#13670B' : 'rgba(0, 0, 0, 0.60)',
            borderBottom: params['*'] === tabName ? '2px solid #13670B' : 'none',
          }}>
          {title}
        </Typography>
      </Link>
    </Grid>
    /*TODO: Remove comment when user has admin permissions
		user?.is_super_admin && (
		<Grid item xs={12} sm={3}>
				<Link to="users" style={classes.subLink}>
				<Typography
						variant='h5'
						p={1}              
						textAlign='center'
						sx={{
						color: params['*'] === 'users' ? '#13670B' : 'rgba(0, 0, 0, 0.60)',                
						borderBottom: params['*'] === 'users' ? '2px solid #13670B' : 'none',
						}}>
						Users
				</Typography>
				</Link>
		</Grid>
				)*/
  );
};

export default NavTabComponent;
