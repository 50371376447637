import { set_error_toast, set_success_toast } from './reducers/dialogReducer';
import { AppDispatch } from './Store';

export const setErrorToastState = (state: boolean, message: string) => (dispatch: AppDispatch) => {
  dispatch(set_error_toast({ state: state, message: message }));
};

export const setSuccessToastState =
  (
    state: boolean,
    message: string,
    artwork_id = null as number | null,
    internal = false as boolean,
  ) =>
  (dispatch: AppDispatch) => {
    dispatch(
      set_success_toast({
        state: state,
        message: message,
        artwork_id: artwork_id,
        internal: internal,
      }),
    );
  };
