import { createSlice, Slice } from '@reduxjs/toolkit';
import { PlatformReducerTypes } from './types/platformReducer.types';

const initialState: PlatformReducerTypes = {
  platform: null,
  loaded: true,
  platforms: {
    loading: false,
    data: [],
    error: '',
    meta: null,
  },
  platform_type: null,
  platform_types: [],
  activity_types: [],
};

export const platformSlice: Slice<PlatformReducerTypes> = createSlice({
  name: 'platform',
  initialState,
  reducers: {
    set_platform: (state, action) => {
      state.platform = action.payload;
    },
    set_platforms: (state, action) => {
      state.platforms = action.payload;
    },
    set_platform_type: (state, action) => {
      state.platform_type = action.payload;
    },
    set_platform_types: (state, action) => {
      state.platform_types = action.payload;
    },
    set_activity_types: (state, action) => {
      state.activity_types = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  set_platform,
  set_platforms,
  set_platform_type,
  set_platform_types,
  set_activity_types,
} = platformSlice.actions;

export default platformSlice.reducer;
