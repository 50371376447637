import { Grid } from '@mui/material';
// Components
import { AdminTitle, AdminNavigation, AdminRoutes, AdminBreadcrumb} from './header';

const Admin: React.FC = () => (
  <Grid container direction="column" p={5}>      
    <AdminBreadcrumb/>
    <AdminTitle/>
    <AdminNavigation/>
    <AdminRoutes/>      
  </Grid>
);

export default Admin;
