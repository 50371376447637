import { createSlice, Slice } from '@reduxjs/toolkit';
import { SponsorshipPageReducerTypes } from './types/sponsorshipPageReducer.types';

const initialState: SponsorshipPageReducerTypes = {
  edit_sponsorship_drawer: false,
  create_sponsorship_drawer: false,
  sponsorship_details_drawer: true,
  delete_sponsorship_dialog: false,
  disable_sponsorship_dialog: false,
};

export const sponsorshipPageSlice: Slice<SponsorshipPageReducerTypes> = createSlice({
  name: 'sponsorshipPage',
  initialState,
  reducers: {
    set_edit_sponsorship_drawer: (state, action) => {
      state.edit_sponsorship_drawer = action.payload;
    },
    set_create_sponsorship_drawer: (state, action) => {
      state.create_sponsorship_drawer = action.payload;
    },
    set_sponsorship_details_drawer: (state, action) => {
      state.sponsorship_details_drawer = action.payload;
    },
    set_delete_sponsorship_dialog: (state, action) => {
      state.delete_sponsorship_dialog = action.payload;
    },
    set_disable_sponsorship_dialog: (state, action) => {
      state.disable_sponsorship_dialog = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  set_edit_sponsorship_drawer,
  set_create_sponsorship_drawer,
  set_sponsorship_details_drawer,
  set_delete_sponsorship_dialog,
  set_disable_sponsorship_dialog,
} = sponsorshipPageSlice.actions;

export default sponsorshipPageSlice.reducer;
