import api from '../../services/api/axios-interceptor';
import { authHeader } from '../../helpers/authHeader';
import { set_user } from '../../Store/reducers/userReducer';
import { AppDispatch } from '../../Store/Store';

export const logoutUser = () => (dispatch: AppDispatch) => {
  return api.post('/logout', {}, { headers: authHeader() }).then(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    return dispatch(set_user(null));
  });
};
