import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface BenchmarkingState {
  data: any; // Replace `any` with the appropriate type for your data
  loading: boolean;
  error: string | null;
}

// Define the initial state using that type
const initialState: BenchmarkingState = {
  data: null,
  loading: false,
  error: null,
};

const benchmarkingSlice = createSlice({
  name: 'benchmarking',
  initialState,
  reducers: {
    fetchBenchmarkingDataRequest(state) {
      state.loading = true;
      state.error = null;
    },
    fetchBenchmarkingDataSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.data = action.payload;
    },
    fetchBenchmarkingDataFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchBenchmarkingDataRequest,
  fetchBenchmarkingDataSuccess,
  fetchBenchmarkingDataFailure,
} = benchmarkingSlice.actions;

export default benchmarkingSlice.reducer;
