import { createSlice, Slice } from '@reduxjs/toolkit';
import { MarketPageReducerTypes } from './types/marketPageReducer.types';

const initialState: MarketPageReducerTypes = {
  edit_market_drawer: false,
  create_market_drawer: false,
  market_details_drawer: false,
  delete_market_dialog: false,
};

export const marketPageSlice: Slice<MarketPageReducerTypes> = createSlice({
  name: 'marketPage',
  initialState,
  reducers: {
    set_edit_market_drawer: (state, action) => {
      state.edit_market_drawer = action.payload;
    },
    set_create_market_drawer: (state, action) => {
      state.create_market_drawer = action.payload;
    },
    set_market_details_drawer: (state, action) => {
      state.market_details_drawer = action.payload;
    },
    set_delete_market_dialog: (state, action) => {
      state.delete_market_dialog = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  set_edit_market_drawer,
  set_create_market_drawer,
  set_market_details_drawer,
  set_delete_market_dialog,
  set_disable_market_dialog,
} = marketPageSlice.actions;

export default marketPageSlice.reducer;
