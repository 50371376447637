import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, SvgIcon } from '@mui/material';
import { AccountCircleOutlined } from '@mui/icons-material';
// State
import { formatTitle } from '../../../helpers/formatTitle';
import { RootState, AppDispatch } from '../../../Store/Store';
import {
  set_create_user_drawer,
  set_user_details_drawer,
  set_edit_user_drawer,
} from '../../../Store/reducers/adminReducer';
import { set_user_details } from '../../../Store/reducers/userReducer';
import {
  set_create_market_drawer,
  set_market_details_drawer,
  set_edit_market_drawer,
} from '../../../Store/reducers/marketPageReducer';
import { set_market_details } from '../../../Store/reducers/marketReducer';
import {
  set_platform_sponsorship_types,
  set_create_sponsorship_type_drawer,
  set_platform_activity_types,
  set_create_activity_type_drawer,
} from '../../../Store/reducers/platformPageReducer';

const AdminBreadcrumb: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const reset = () => {
    dispatch(set_create_user_drawer(false));
    dispatch(set_user_details_drawer(false));
    dispatch(set_edit_user_drawer(false));
    dispatch(set_user_details(null));
    dispatch(set_create_market_drawer(false));
    dispatch(set_market_details_drawer(false));
    dispatch(set_edit_market_drawer(false));
    dispatch(set_market_details(null));
    dispatch(set_platform_sponsorship_types(false));
    dispatch(set_create_sponsorship_type_drawer(false));
    dispatch(set_platform_activity_types(false));
    dispatch(set_create_activity_type_drawer(false));
  };

  const params = useParams();
  const { user_details_drawer, edit_user_drawer } = useSelector(({ admin }: RootState) => admin);
  const user_details = useSelector(({ user }: RootState) => user.user_details);
  const { market_details_drawer, edit_market_drawer } = useSelector(
    ({ marketPage }: RootState) => marketPage,
  );
  const market_details = useSelector(({ market }: RootState) => market.market_details);
  const { platform_sponsorship_types, create_sponsorship_type_drawer, platform_activity_types } =
    useSelector(({ platformPage }: RootState) => platformPage);

  const navigate = useNavigate();
  const navigateToAdmin = () => {
    navigate(`/admin/general`);
  };

  return (
    <Grid item>
      <Grid container direction="row" justifyContent="flex-start" spacing={1} alignItems="center">
        <Grid item marginTop={0.5}>
          <SvgIcon component={AccountCircleOutlined} fontSize="inherit" />
        </Grid>
        <Grid item>
          <Typography
            sx={{ cursor: 'pointer' }}
            onClick={navigateToAdmin}
            textTransform="capitalize">
            Admin
          </Typography>
        </Grid>
        <Grid item>
          <Typography>/</Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ cursor: 'pointer' }} onClick={() => reset()} textTransform="capitalize">
            {params['*'] && formatTitle(params['*'])}
          </Typography>
        </Grid>
        {user_details && (user_details_drawer || edit_user_drawer) && (
          <Grid item>
            <Typography>/</Typography>
          </Grid>
        )}
        {user_details && (user_details_drawer || edit_user_drawer) && (
          <Grid item>
            <Typography textTransform="capitalize">{user_details?.name}</Typography>
          </Grid>
        )}
        {market_details && (market_details_drawer || edit_market_drawer) && (
          <Grid item>
            <Typography>/</Typography>
          </Grid>
        )}
        {market_details && (market_details_drawer || edit_market_drawer) && (
          <Grid item>
            <Typography textTransform="capitalize">{market_details?.name}</Typography>
          </Grid>
        )}
        {(platform_sponsorship_types || create_sponsorship_type_drawer) && (
          <Grid item>
            <Typography>/</Typography>
          </Grid>
        )}
        {(platform_sponsorship_types || create_sponsorship_type_drawer) && (
          <Grid item>
            <Typography
              sx={{ cursor: 'pointer' }}
              onClick={() => dispatch(set_platform_activity_types(false))}
              textTransform="capitalize">
              Sponsorship Types
            </Typography>
          </Grid>
        )}
        {platform_activity_types && (
          <Grid item>
            <Typography>/</Typography>
          </Grid>
        )}
        {platform_activity_types && (
          <Grid item>
            <Typography textTransform="capitalize">Activity Type</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AdminBreadcrumb;
